

































































































































import {
  Component, Vue, Watch, Ref
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  AdminAction, AdminActionType, AdminState, UserGetter, UserGetterType
} from '@/store/modules/admin/types';
import { Models } from '@mtap-smartcity/api';

const admin = namespace('admin');

// const userObj: Models.Bff.Auth.Users.Model = {
const userObj: Models.Bff.Auth.Users.Model = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  groupId: [],
  username: '',
  enabled: false,
  attributes: {
    phone: [''],
  },
  requiredActions: [],
  alerts: {
    email: false,
    phone: false,
  },
};

@Component
/**
 * @group Users Card
 * Users tab right panel - User settings
 */
export default class UsersCardRightPanelUserSettings extends Vue {
  @Ref() form!: any

  @Ref() emailField!: any

  userData: Models.Bff.Auth.Users.Model = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    groupId: [],
    username: '',
    enabled: false,
    attributes: {
      phone: [''],
    },
    requiredActions: [],
    alerts: {
      email: false,
      phone: false,
    },
  }

  valid = false;

  emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  phoneFormat = /^([0-9+–—()-]+\s)*[0-9+–—()-]+$/;

  editState = false;

  emailFrame = false;

  emailRules: any[] = [(v) => !!v]

  emailFocusLost = false;

  @admin.State
  selectedUser!: AdminState['selectedUser']

  @admin.State
  createUserFlag!: AdminState['createUserFlag']

  @admin.State
  deleteUserFlag!: AdminState['deleteUserFlag']

  @admin.Action(AdminAction.UpdateUser)
  updateUser!: AdminActionType['UPDATE_USER']

  @admin.Action(AdminAction.SetCreateUserFlag)
  SetCreateUserFlag!: AdminActionType['SET_CREATE_USER_FLAG'];

  @admin.Action(AdminAction.CreateUser)
  createUser!: AdminActionType['CREATE_USER'];

  @admin.Action(AdminAction.RemoveUserPlaceholder)
  removeUserPlaceholder!: AdminActionType['REMOVE_USER_PLACEHOLDER'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  get permissionCheck() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'users');
    return getPermissions.write;
  }

  get emailValid() {
    return this.emailFormat.test(this.userData.email);
  }

  get phoneNumberRules() {
    return this.userData.alerts.phone ? [(v: string) => !!v, (v: string) => this.phoneFormatTest(v)] : [(v: string) => this.phoneFormatTest(v)];
  }

  @Watch('selectedUser', { deep: true, immediate: true })
  onSelectedUserChange(newUser: Models.Bff.Auth.Users.Model) {
    if (newUser) {
      this.userData = JSON.parse(JSON.stringify(newUser));
    } else {
      this.userData = { ...userObj };
    }
  }

  @Watch('createUserFlag')
  onCreateUserFlag(createUserFlag) {
    this.$nextTick(() => {
      // @ts-ignore
      this.emailField.focus();
    });
    this.editState = createUserFlag;
    this.userData = { ...userObj };
    // @ts-ignore
    this.form.resetValidation();
    // @ts-ignore
    this.form.reset();
  }

  @Watch('deleteUserFlag')
  onDeleteUserFlag() {
    this.userData = { ...userObj };
  }

  checkEmailFormat() {
    if (this.userData.email?.length === 0 || this.emailValid) {
      this.emailRules = [(v) => !!v];
    }
    if (this.emailRules.length === 2) return;
    // eslint-disable-next-line no-return-assign
    setTimeout(() => this.emailRules = [(v) => !!v, (v) => this.emailFormat.test(v) || 'Format: xxx@host.com'], 1500);
  }

  phoneFormatTest(value) {
    return value ? this.phoneFormat.test(value) : true;
  }

  save() {
    if (!this.valid) return;
    // @ts-ignore
    this.form.validate();
    if (!this.emailFormat.test(this.userData.email)) {
      this.emailFrame = true;
      return;
    }
    if (this.createUserFlag) {
      delete this.userData.groupId;
      this.createUser(this.userData)
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.userData = { ...userObj };
          delete this.userData.attributes?.phone[0];
          this.editState = false;
          this.SetCreateUserFlag();
          this.emailFrame = false;
          this.emailRules = [(v) => !!v];
        });
    } else {
      delete this.userData.groupId;
      this.updateUser(this.userData)
        .catch(() => {
          this.userData = this.selectedUser ? JSON.parse(JSON.stringify(this.selectedUser)) : { ...userObj };
        })
        .finally(() => {
          this.editState = false;
          this.emailRules = [(v) => !!v];
        });
    }
  }

  edit() {
    this.editState = true;
    this.valid = true;
    // @ts-ignore
    this.form.resetValidation();
  }

  cancel() {
    // @ts-ignore
    this.form.resetValidation();
    if (!this.createUserFlag) {
      this.userData = this.selectedUser ? JSON.parse(JSON.stringify(this.selectedUser)) : { ...userObj };
      this.editState = false;
      this.emailRules = [(v) => !!v];
    } else {
      this.userData = { ...userObj };
      this.editState = false;
      this.SetCreateUserFlag();
      this.emailFrame = false;
      this.emailRules = [(v) => !!v];
    }
  }

  beforeDestroy() {
    if (this.createUserFlag === true) {
      this.SetCreateUserFlag();
    }
  }

  destroyed() {
    this.userData = { ...userObj };
    this.editState = false;
    this.emailRules = [(v) => !!v];
  }
}
